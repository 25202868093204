import React from "react"
import PropTypes from "prop-types"
import { TransitionGroup, Transition } from "react-transition-group"

const transitionStyles = {
  entering: {
    position: `absolute`,
    transition: `opacity 200ms ease-in`,
    opacity: 1,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    transition: `opacity 200ms ease-out 100ms`,
    opacity: 0,
  },
}

const PageTransition = props => {
  const { style, className, location, timeout, children } = props

  return (
    <TransitionGroup>
      <Transition key={location} timeout={timeout}>
        {status => (
          <div
            className={className}
            style={{
              opacity: 0,
              ...style,
              ...transitionStyles[status],
            }}
          >
            {children}
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

PageTransition.propTypes = {
  location: PropTypes.string,
  timeout: PropTypes.number,
  children: PropTypes.element.isRequired,
}

PageTransition.defaultProps = {
  location: "",
  timeout: 250,
}
export default PageTransition
