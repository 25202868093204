import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const Wrapper = styled.div`
  position: fixed;
  z-index: 99999;
  cursor: none;
  pointer-events: none;
  margin-left: 20px;
  padding: 3px 5px;
  font-size: 12px;
  background-color: gray;
  color: #000;
  border: 4px solid black;

  .touchevents & {
    opacity: 0;
    visibility: hidden;
  }
`

const Cursor = ({ message }) => {
  const cursorRef = useRef()

  useEffect(() => {
    const mouseMove = e => {
      cursorRef.current.style.top = `${e.pageY}px`
      cursorRef.current.style.left = `${e.pageX}px`
    }

    document.addEventListener("mousemove", mouseMove)

    return () => {
      document.removeEventListener("mousemove", mouseMove)
    }
  }, [])

  return (
    <Wrapper ref={cursorRef} style={{ display: message ? "block" : "none" }}>
      {message}
    </Wrapper>
  )
}

export default Cursor
