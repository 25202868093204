export default {
  breakpoints: {
    small: "48em",
    medium: "64em",
    large: "90em",
    hd: "120em",
  },
  fontSizes: {
    default: "24px",
    lexique: "14px",
    title: "31px",
    subtitle: "28px",
  },
  colors: {
    background: "#393739",
    loading: "black",
    overlay: "rgba(0, 0, 0, .4)",
    controls: "rgba(64, 66, 68, .9)",
  },
  borders: {
    controls: "10px solid black",
  },
  space: {
    gutter: "20px",
    large: "40px",
  },
  fonts: {
    body: "sans-serif",
  },
  fontWeights: {
    normal: 300,
    bold: 600,
  },
  lineHeights: {},
  zIndices: {
    loading: 9999,
    controls: 9000,
    logo: 8000,
  },
}
