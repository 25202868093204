import React, { useState, useEffect, useCallback, createContext } from "react"

const LoadingContext = createContext({
  loading: true,
  startLoading: () => {},
  stopLoading: () => {},
})

// Set Active to loading component
const withLoading = (Component, debounceTimeout = 2000) => props => {
  const [active, setActive] = useState(true)
  const [debounce, setDebounce] = useState(true)

  // Debounce loading
  const startDebounce = useCallback(() => {
    setTimeout(() => setDebounce(false), debounceTimeout)
  }, [setDebounce, debounceTimeout])

  // Actions
  const startLoading = useCallback(() => {
    setActive(true)
    setDebounce(true)
    startDebounce()
  }, [setActive, startDebounce])

  const stopLoading = useCallback(() => {
    setActive(false)
  }, [setActive])

  // Init
  useEffect(() => {
    startDebounce()
  }, [startDebounce])

  return (
    <LoadingContext.Provider
      value={{
        loading: active || debounce,
        startLoading,
        stopLoading,
      }}
    >
      <Component {...props} />
    </LoadingContext.Provider>
  )
}

export { LoadingContext, withLoading }
