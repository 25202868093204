import React, {
  createContext,
  useCallback,
  useState,
  useRef,
  useEffect,
} from "react"
import PropTypes from "prop-types"
import ambiantURL from "@src/assets/sounds/1-Son Principal Ambiance Gym.mp3"

const MediaContext = createContext({
  audioEnabled: false,
  muted: false,
  mute: () => {},
  unmute: () => {},
  authorizeAudio: () => {},
  playSample: () => {},
  playAmbiant: () => {},
})

const withMedia = Component => props => {
  const [audioEnabled, setAudioEnabled] = useState(false)
  const [muted, setMuted] = useState(true)
  const ambiant = useRef()
  const samples = useRef()
  const contextAudio = useRef()

  useEffect(() => {
    contextAudio.current = new (window.AudioContext ||
      window.webkitAudioContext)()
  }, [])

  const playSample = useCallback(async (src, loop = false) => {
    samples.current.src = src
    samples.current.loop = loop
    await samples.current.load()
    await samples.current.play()
  }, [])

  const playAmbiant = useCallback(async () => {
    if (ambiant.current.paused) await ambiant.current.play()
  }, [])

  const authorizeAudio = useCallback(async () => {
    setAudioEnabled(true)
    setMuted(false)
    await contextAudio.current.resume()
  }, [setMuted, setAudioEnabled])

  const mute = useCallback(() => {
    setMuted(true)
  }, [setMuted])

  const unmute = useCallback(async () => {
    authorizeAudio()
    setMuted(false)
  }, [setMuted, authorizeAudio, playAmbiant])

  useEffect(() => {
    ambiant.current.muted = muted
    samples.current.muted = muted
    ambiant.current.volume = muted ? 0 : 0.6
    samples.current.volume = muted ? 0 : 1
  }, [muted])

  return (
    <MediaContext.Provider
      value={{
        audioEnabled,
        muted,
        mute,
        unmute,
        authorizeAudio,
        playSample,
        playAmbiant,
      }}
    >
      <audio muted={true} ref={ambiant} loop={true} src={ambiantURL}></audio>
      <audio muted={true} ref={samples}></audio>
      <Component {...props} />
    </MediaContext.Provider>
  )
}

export { MediaContext, withMedia }
