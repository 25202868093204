import React from "react"
import PropTypes from "prop-types"
import { Transition } from "react-transition-group"
import styled from "@emotion/styled"

import posterURL from "@src/assets/images/loading.png"
import mp4URL from "@src/assets/images/loading.mp4"
import webmURL from "@src/assets/images/loading.webm"

const loadingTransition = {
  entering: {
    opacity: 0,
  },
  entered: {
    transition: `opacity 250ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity 250ms ease-in-out`,
    opacity: 0,
  },
  exited: {
    display: "none",
  },
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.loading};
  z-index: ${props => props.theme.zIndices.loading};
`

const Video = styled.video`
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const Loading = ({ active, timeout = 200 }) => (
  <Transition in={active} timeout={timeout}>
    {state => (
      <Wrapper style={{ ...loadingTransition[state] }}>
        <Video poster={posterURL} autoPlay muted loop>
          <source src={webmURL} type="video/webm" />
          <source src={mp4URL} type="video/mp4" />
        </Video>
      </Wrapper>
    )}
  </Transition>
)

Loading.propTypes = {
  active: PropTypes.bool,
}

export default Loading
