import React from "react"
import WrapPage from "@src/layouts/WrapPage"
import WrapRoot from "@src/layouts/WrapRoot"

export const wrapRootElement = ({ element }) => {
  return <WrapRoot>{element}</WrapRoot>
}

export const wrapPageElement = ({ element, props }) => {
  return <WrapPage {...props}>{element}</WrapPage>
}

export const onClientEntry = () => {
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
  }
}
