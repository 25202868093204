import React, { useContext, useEffect } from "react"
import styled from "@emotion/styled"
import { withLoading, LoadingContext } from "@src/contexts/LoadingContext"
import { withMedia, MediaContext } from "@src/contexts/MediaContext"
import { withCursor, CursorContext } from "@src/contexts/CursorContext"
import Loading from "@src/components/Loading"
import Cursor from "@src/components/Cursor"
import PageTransition from "@src/components/PageTransition"
import logoURL from "@src/assets/images/logo.png"
import SEO from "@src/components/seo"
const transitionTimeout = 300

const Logo = styled.a`
  display: block;
  outline: none;
  position: fixed;
  transition: opacity 200ms;
  right: 35px;
  top: 35px;
  z-index: ${props => props.theme.zIndices.logo};

  img {
    display: block;
    width: 80px;
  }
`

const WrapPage = props => {
  const { location, children } = props
  const { loading } = useContext(LoadingContext)
  const { message, setMessage } = useContext(CursorContext)

  useEffect(() => {
    if (loading) setMessage("please wait")
    else setMessage("")
  }, [loading])

  return (
    <>
      <SEO />
      <Loading active={loading} timeout={transitionTimeout} />
      <Cursor message={message} />
      <Logo href="https://sample-cm.com" target="_blank">
        <img src={logoURL} alt="logo" />
      </Logo>
      <PageTransition location={location.pathname} timeout={transitionTimeout}>
        {children}
      </PageTransition>
    </>
  )
}

export default [withLoading, withMedia, withCursor].reduce(
  (c, w) => w(c),
  WrapPage
)
