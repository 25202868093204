import React from "react"
import "normalize.css"
import { ThemeProvider } from "emotion-theming"
import preset from "@src/theme"
import { css, Global } from "@emotion/core"
import "@src/assets/fonts/muli.css"
import "@src/assets/fonts/FightNight.css"

const WrapRoot = ({ children }) => {
  return (
    <ThemeProvider theme={preset}>
      <>
        <Global
          styles={theme => css`
            body {
              font-family: muli, sans-serif;
              font-weight: ${theme.fontWeights.normal};
              font-style: normal;
              background-color: ${theme.colors.background};
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              font-weight: ${theme.fontWeights.bold};
            }
          `}
        />
        {children}
      </>
    </ThemeProvider>
  )
}

export default WrapRoot
