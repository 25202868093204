import React, { useState, useEffect, useCallback, createContext } from "react"

const CursorContext = createContext({
  message: "",
  setMessage: () => {},
})

// Set Active to loading component
const withCursor = Component => props => {
  const [message, setMessage] = useState("")

  return (
    <CursorContext.Provider
      value={{
        message,
        setMessage,
      }}
    >
      <Component {...props} />
    </CursorContext.Provider>
  )
}

export { CursorContext, withCursor }
